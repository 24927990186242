<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Jimbo
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Bienvenue ! 👋🏻
          </p>
          <p class="mb-2">
            Merci de vous connecter pour accéder au CRM
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="username"
              outlined
              label="Identifiant"
              :rules="[v => !!v || 'Saisir votre identifiant']"
              placeholder="Identifiant ou e-mail"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :rules="[v => !!v || 'Saisir un mot de passe']"
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Mot de passe"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Se souvenir de moi"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->

              <a
                color="primary"
                class="mt-1"
                @click="$router.push({name:'forgot-password'})"
              >
                Mot de passe oublié ?
              </a>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="login"
            >
              Connexion
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-text>
          <a
            color="primary"
            class="mt-1"
            @click="$router.push({name:'pages-register'})"
          >
            Créer un compte
          </a>
        </v-card-text>
        <small class="d-flex justify-end">Powered by Jimbo</small>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  data() {
    return {
      username: '',
      password: '',
      isPasswordVisible: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  mounted() {
    this.$store.dispatch('logout')
  },

  methods: {
    login() {
      this.$store.dispatch('login', {
        identifier: this.username,
        password: this.password,
      }).then(() => {
        this.$router.push('/')
      })
        .catch(err => {
          console.log(err.response.data)
          if (err.response.data.statusCode === 403) {
            this.$store.commit('snackMe', {
              color: 'warning',
              text: 'Vous êtes déjà connecté, redirection vers la page d\'accueil',
              value: true,
            })
            this.$router.push('/')
          } else {
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur lors de l\'identification',
              value: true,
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
